import { Doughnut } from 'react-chartjs-2'
import { ArcElement, Tooltip, Chart as Chartjs } from 'chart.js'
import { ReactNode } from 'react'
import { getPriceFromName } from 'state/slices/app/brokers'
import { MetaBroker } from 'state/mock'
import { types } from '@concordia/super-sdk'
import { noteNameToBroker } from 'toolbox/account'
import {
  MOVEMENT_NAME,
  MOVEMENT_USDC_ASSET_NAME,
  MOVEMENT_USDT_ASSET_NAME,
  MOVEMENT_MBTC_ASSET_NAME,
  MOVEMENT_WBTC_ASSET_NAME,
  MOVEMENT_WETH_ASSET_NAME,
  STAKED_MOVMENT_NAME
} from 'state/tokens'
Chartjs.register(ArcElement)
Chartjs.register(Tooltip)

export interface Set {
  label: string
  data: number[]
  backgroundColor: string[]
  hoverOffset: number
  borderColor: string
}

export interface CData {
  labels: string[]
  datasets: Set[]
}

export interface BalanceChartProps {
  children?: ReactNode
  positions: types.NameBalanceMap
  prices: types.NameBalanceMap
  brokers: MetaBroker[]
}

export const colorMapping = {
  [MOVEMENT_NAME]: '#9224ff',
  [MOVEMENT_USDT_ASSET_NAME]: '#0fa9a0',
  [MOVEMENT_USDC_ASSET_NAME]: '#5886cc',
  [MOVEMENT_WETH_ASSET_NAME]: '#ff6cd6',
  [MOVEMENT_WBTC_ASSET_NAME]: '#b02892',
  [STAKED_MOVMENT_NAME]: '#c7cb0d',
  [MOVEMENT_MBTC_ASSET_NAME]: '#bb5a3b'
}

export const colorClassMapping = {
  [MOVEMENT_NAME]: 'c-1',
  [MOVEMENT_USDT_ASSET_NAME]: 'c-2',
  [MOVEMENT_USDC_ASSET_NAME]: 'c-3',
  [MOVEMENT_WETH_ASSET_NAME]: 'c-4',
  [MOVEMENT_WBTC_ASSET_NAME]: 'c-5',
  [STAKED_MOVMENT_NAME]: 'c-6',
  [MOVEMENT_MBTC_ASSET_NAME]: 'c-7'
}

function BalanceChart({ children, positions, prices, brokers }: BalanceChartProps) {
  if (!positions) {
    return
  }
  const balKeys = Object.keys(positions)
  const dataVals = []
  const backgroundColors = [] // Array to hold the colors for the dataset
  const labels = [] // Initialize labels array

  balKeys.forEach((noteName) => {
    const value = positions[noteName] * getPriceFromName(noteName, prices)
    const broker = noteNameToBroker(brokers, noteName)
    const label = broker?.underlyingAsset.name
    labels.push(label) // Add label
    dataVals.push(value) // Add value
    backgroundColors.push(colorMapping[label]) // Assign color based on mapping or default color
  })

  // Sort the dataVals and align the labels and backgroundColors with the sorted data
  const indices = dataVals.map((value, index) => index).sort((a, b) => dataVals[b] - dataVals[a])
  const sortedDataVals = indices.map((index) => dataVals[index])
  const sortedLabels = indices.map((index) => labels[index])
  const sortedBackgroundColors = indices.map((index) => backgroundColors[index])

  const cd: CData = {
    labels: sortedLabels,
    datasets: [
      {
        label: 'Supply Balance (Dollars)',
        data: sortedDataVals,
        backgroundColor: sortedBackgroundColors,
        hoverOffset: 2,
        borderColor: '#1A1A1A'
      }
    ]
  }

  return (
    <div className="doughnut-chart-container">
      <Doughnut
        data={cd}
        options={{
          animation: { animateRotate: true },
          cutout: 113,
          backgroundColor: '#1A1A1A',
          rotation: 180,
          responsive: true,
          interaction: {
            mode: 'nearest'
          },
          plugins: {
            tooltip: {
              enabled: false,
              position: 'average'
            }
          }
        }}
      />
      {!!children && children}
    </div>
  )
}

export default BalanceChart
