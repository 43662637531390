import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { SdkContext, SDK_CONTEXT } from 'state/context'
import { Routing } from './Routing'
import TopNav from 'layout/TopNav'
import {
  fetchAppData,
  fetchHistories,
  fetchHistoriesPrice,
  fetchPoints,
  fetchPortUser,
  fetchUserWalletBalances
} from 'state/fetch'
import { AppModals } from './AppModals'
import WidgetContainer from 'components/wallet/WidgetContainer'
import Footer from './Footer'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import {
  selectBrokersErrored,
  selectBrokersLoaded,
  selectUnderlyingNamesFromBrokers
} from 'state/slices/app/brokers'
import { useAppSelector } from 'state/hooks'
import { isEmptyOrNil } from 'toolbox/account'
import Hotjar from '@hotjar/browser'
import ReactGA from 'react-ga'
import { setTxErrorState } from 'state/slices/ui/transaction'
import { selectPortfolioErrored } from 'state/slices/user/portfolio'
import {
  closeBlock,
  resetBlocked,
  selectIsBlocked,
  selectShowBlockModal,
  setBlocked,
  showBlock
} from 'state/slices/ui/dash'
import { useLocation } from 'react-router-dom'
import { openDoReferModal, openReferralBanner } from 'state/slices/app/points'
import ReferralBanner from 'components/points/ReferralBanner'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export interface InnerContext {
  sdk: SdkContext
}

export interface AppContext {
  context: InnerContext
  setContext: Dispatch<SetStateAction<InnerContext>>
}

const DEFAULT_CONTEXT: AppContext = {
  context: { sdk: SDK_CONTEXT },
  setContext: () => {}
}

export const Context = createContext<AppContext>(DEFAULT_CONTEXT)

console.log('ENV: ', process.env.REACT_APP_ENV)
console.log('SUPER API URL:', process.env.REACT_APP_SUPER_JSON_API_URL)
console.log('APTOS ROOT: ', process.env.REACT_APP_APTOS_ROOT_ADDRESS)
console.log('APTOS FAUCET: ', process.env.REACT_APP_APTOS_FAUCET_ADDRESS)
console.log('APTOS MAIN URL: ', process.env.REACT_APP_APTOS_MAIN_URL)

const TRACKING_ID_GA = 'G-QPECQG93XF'
const SITE_ID_HOTJAR = 4939784

function InnerApp() {
  const [context, setContext] = useState(DEFAULT_CONTEXT.context)
  const { sdk } = context
  const { superSdk } = sdk
  const { connected, account } = useWallet()
  const underlyingNames = useAppSelector(selectUnderlyingNamesFromBrokers)
  const isBrokersLoaded = useAppSelector(selectBrokersLoaded)
  const brokersErroredAfterRetry = useAppSelector(selectBrokersErrored)
  const portfolioErroredAfterRetry = useAppSelector(selectPortfolioErrored)
  const isBlocked = useAppSelector(selectIsBlocked)
  const showBlockModal = useAppSelector(selectShowBlockModal)
  const { pathname } = useLocation()
  const dashSelected = pathname === '/'

  // useEffect(() => {
  //   const checkIp = async () => {
  //     try {
  //       const isIpOk = await sdk.superSdk.fetcher.checkValidIp()
  //       console.log('checked IP: ', isIpOk)
  //       if (!isIpOk) {
  //         setBlocked()
  //         showBlock()
  //       } else {
  //         resetBlocked()
  //       }
  //     } catch (e) {
  //       console.log('Error checking IP region:', e.message)
  //       setBlocked()
  //       showBlock()
  //     }
  //   }

  //   checkIp()
  // }, [sdk.superSdk.fetcher])

  // useEffect(() => {
  //   if (!isBlocked && showBlockModal) {
  //     closeBlock()
  //   }
  // }, [isBlocked, showBlockModal])

  useEffect(() => {
    const siteId = SITE_ID_HOTJAR
    const hotjarVersion = 6
    if (process.env.REACT_APP_ENV === 'stable-mainnet') {
      Hotjar.init(siteId, hotjarVersion, {
        debug: true
      })
      ReactGA.initialize(TRACKING_ID_GA)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }, [])

  // load initial system data
  useEffect(() => {
    if (!superSdk) {
      return
    }
    fetchAppData()
  }, [superSdk])

  //load broker histories
  useEffect(() => {
    if (!isEmptyOrNil(underlyingNames) && !isEmptyOrNil(account?.address)) {
      const hasAddress = account?.address
      const addressOrEmpty = hasAddress ? account?.address : ''
      fetchHistories(underlyingNames, addressOrEmpty as string)
      // fetchHistoriesPrice(underlyingNames)
    }
  }, [underlyingNames, account?.address])

  //load user portfolio and risk and history
  useEffect(() => {
    if (!connected) {
      return
    }

    fetchPortUser(account?.address as string)
    fetchUserWalletBalances(account?.address as string)
  }, [connected, account?.address])

  //fetch points
  // useEffect(() => {
  //   fetchPoints()
  // })

  //fetch broker and histories on timeout
  useEffect(() => {
    const fetchAllInterval = () => {
      fetchAppData()
      if (!isEmptyOrNil(underlyingNames)) {
        const hasAddress = account?.address
        const addressOrEmpty = hasAddress ? account?.address : ''
        // fetchHistories(underlyingNames, addressOrEmpty as string)
      }

      if (!isEmptyOrNil(underlyingNames)) {
        // fetchHistoriesPrice(underlyingNames)
      }
    }

    const intervalId = setInterval(fetchAllInterval, 120000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  //fetch user on timeout
  useEffect(() => {
    if (connected) {
      const fetchUser = () => {
        fetchPortUser(account?.address as string)
        fetchUserWalletBalances(account?.address as string)
      }

      const intervalId = setInterval(fetchUser, 120000)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [connected, account?.address])

  //check if broker fetch errored
  useEffect(() => {
    if (brokersErroredAfterRetry) {
      const txErrorState = {
        title: 'Testnet Overload!',
        message: `We're currently unable to load the broker data due to the immense amount of traffic occurring on Movement’s testnet at this time.
This increased load can lead to temporary limitations, which is why some requests aren’t going through as expected.  Please refresh or try again later.  This is what testnets are for!  You are actively helping the team ensure a smooth mainnet launch.`
      }
      setTxErrorState(txErrorState)
    }
  }, [brokersErroredAfterRetry])

  //check if portfolio fetch errors
  useEffect(() => {
    if (portfolioErroredAfterRetry) {
      const txErrorState = {
        title: 'Testnet Overload!',
        message: `We're currently unable to load the portfolio data due to the immense amount of traffic occurring on Movement’s testnet at this time.
This increased load can lead to temporary limitations, which is why some requests aren’t going through as expected.  Please refresh or try again later.  This is what testnets are for!  You are actively helping the team ensure a smooth mainnet launch.`
      }
      setTxErrorState(txErrorState)
    }
  }, [portfolioErroredAfterRetry])

  //referral code check
  const [referralCode, setReferralCode] = useState<string | null>(null)
  const query = useQuery()

  useEffect(() => {
    const code = query.get('refCode')
    if (code) {
      setReferralCode(code)
      openReferralBanner()
    }
  }, [query])

  useEffect(() => {
    if (referralCode) {
      openDoReferModal()
    }
  }, [referralCode])

  const hideScrollClass = dashSelected && !connected ? 'hide-scroll' : ''

  return (
    <>
      <Context.Provider value={{ context, setContext }}>
        <div id="App" className={hideScrollClass}>
          <ReferralBanner />
          <TopNav />
          <section className="section-wrapper">
            <div className="section">
              <Routing />
            </div>
          </section>
          <section className="section-wrapper">
            <div className="section no-height">
              <Footer />
            </div>
          </section>
        </div>
        <AppModals />
        <WidgetContainer />
        <Toaster position="bottom-left" />
      </Context.Provider>
    </>
  )
}

export default InnerApp
